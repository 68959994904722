import { runVPOnHandles } from './runOnHandles';
import { runVPOnIds, removeGoodiesFromCart } from './runOnIds';
import { initLightRegister } from './initLightRegister';
import { getVPMetadata } from './apiCalls';
import { handleThirdPartyApps } from './thirdPartyAppIntegrations';

import { version } from '../package.json';

export function init($ = required('jquery')) {
  /**
   * Call initVP to init and run VP (and lightregister) on the website
   * @param themeMoneyFormat
   */
  const initVP = async (themeMoneyFormat = null) => {
    const currency = themeMoneyFormat ? themeMoneyFormat : window.__ozjs?.privateSales?.currency;
    const TEST_MODE = window.__ozjs?.privateSales?.testMode || false;

    const vpIsRunning = (() => {
      const { privateSales } = getVPMetadata;
      return (
        (getVPMetadata && privateSales && privateSales.isActivated && privateSales.url) || false
      );
    })();

    const defaultMoneyFormat = '{{amount_with_comma_separator}}€';
    const moneyFormat = currency ? currency : defaultMoneyFormat;
    const shopName = window.Shopify.shop;

    if (TEST_MODE || vpIsRunning) {
      console.log('*** TOZ Ope. com. app is running');
      const RUN_VP = () => {
        runVPOnHandles(shopName, moneyFormat);
        runVPOnIds(shopName, moneyFormat);
      };

      // CHECKOUT EXTENSIBILITY clean up the cart
      await removeGoodiesFromCart();
      // update prices
      handleThirdPartyApps();
      RUN_VP();

      if (
        $(
          'body.page-lightregister, body.template-suffix-lightregister, .oz-lightregister, body.lightregister--template',
        ).length ||
        window.location.pathname.indexOf('login') > -1
      ) {
        initLightRegister();
      }

      document.addEventListener('vp-rerender', RUN_VP);
    }
  };

  return {
    run: () => {
      window.vpDeps = { $ };
      initVP();
      window.initVP = initVP;
      window.vpVersion = (() => {
        console.log(`*** TOZ Ope. com. app - version: ${version}`);
        return version;
      })();
    },
  };
}

function required(name) {
  throw new Error(`${name} required`);
}
