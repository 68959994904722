import { clDebug } from './utils/utils';

const getRandomPromotion = (() => {
  const per = [0.1, 0.25, 0.35, 0.5, 0.75];
  const randPer = per[Math.floor(Math.random() * per.length)];
  return randPer;
})();

export function getMockApiReturnFromVariants(variants) {
  let ret = `{
    "user_id": 5044938997869,
    "products_prices": null,
    "variants_prices": [`;
  for (let i = 0; i < variants.length; i++) {
    const randomNumber = (Math.random(1, 2) * 100).toFixed(2);
    const randomPrice = Number(randomNumber - randomNumber * getRandomPromotion).toFixed(2);
    const compareAtPrice = randomNumber;
    ret += `{
      "variant_id": ${variants[i]},
      "price": ${randomPrice},
      "compare_at_price": ${compareAtPrice}
      }`;
    if (i < variants.length - 1) ret += `,`;
  }
  ret += `]}`;
  clDebug(`*** (getMockApiReturnFromVariants) :: res: ${ret}`);
  return JSON.parse(ret);
}

export function getMockApiReturnFromHandles(handles) {
  let ret = `{
    "user_id": 5044938997869,
    "products_prices": [`;
  for (let i = 0; i < handles.length; i++) {
    const randomNumber = (Math.random(1, 2) * 100).toFixed(2);
    const randomMinPrice = Number(randomNumber - randomNumber * getRandomPromotion).toFixed(2);
    const compareAtPrice = randomNumber;
    ret += `{
      "product_handle": "${handles[i]}",
      "min_price": ${randomMinPrice - 1},
      "max_price": ${compareAtPrice - 1},
      "min_compare_at_price": ${randomMinPrice},
      "max_compare_at_price": ${compareAtPrice}
      }`;
    if (i < handles.length - 1) ret += `,`;
  }
  ret += `],"variants_prices": null }`;
  clDebug(`*** (getMockApiReturnFromHandles) :: res: ${ret}`);
  return JSON.parse(ret);
}

export function getMockFidelityCouponsApi() {
  const ret = `{
      "coupons": [
        { "coupon": "FIDMOCK0${('0' + Math.ceil(Math.random() * 100)).slice(-2)}", "value": 10,
        "expiracyDate" : "${new Date().toISOString()}"
       },
        { "coupon": "FIDMOCK0${('0' + Math.ceil(Math.random() * 100)).slice(-2)}", "value": 5,
        "expiracyDate" : "${new Date().toISOString()}" }
      ]
    } 
  `;
  clDebug(`*** (getMockFidelityCouponApi) :: res: ${ret}`);
  return JSON.parse(ret);
}

export function getMockCartApiReturn(lineItems, coupons = []) {
  let cartCompareAtPrice = 0;
  let cartOriginalCompareAtPrice = 0;
  let cartItemsSubtotalPrice = 0;
  let ret = `{
    "user_id": 5044938997869,
    "line_items": [`;
  for (let i = 0; i < lineItems.length; i++) {
    const quantity = lineItems[i].quantity;
    const compareAtPrice = (Math.random(1, 2) * 100).toFixed(2);
    const lineCompareAtPrice = (Number(compareAtPrice) * quantity).toFixed(2);
    const originalPrice = Number(compareAtPrice - compareAtPrice * getRandomPromotion).toFixed(2);
    const originalLinePrice = originalPrice * quantity;
    const finalPrice = Number(originalPrice - originalPrice * getRandomPromotion).toFixed(2);
    const finalLinePrice = Number(finalPrice) * quantity;

    const vpDiscounts = originalLinePrice - finalLinePrice;
    const discountAllocationAmount1 = Number(vpDiscounts * 0.7).toFixed(2);
    const discountAllocationAmount2 = Number(vpDiscounts - discountAllocationAmount1).toFixed(2);
    const discountApplication1 = 'VP_2021';
    const discountApplication2 = '10%';

    cartCompareAtPrice = Number(cartCompareAtPrice) + Number(lineCompareAtPrice);
    cartOriginalCompareAtPrice = Number(cartOriginalCompareAtPrice) + Number(originalLinePrice);
    cartItemsSubtotalPrice = Number(cartItemsSubtotalPrice) + Number(finalLinePrice);

    ret += `{
      "variant_id": ${lineItems[i].variant_id},
      "quantity": ${lineItems[i].quantity},
      "line_compare_at_price": "${lineCompareAtPrice}",
      "compare_at_price": "${compareAtPrice}",
      "original_line_price": "${originalLinePrice}",
      "original_price": "${originalPrice}",
      "final_line_price": "${finalLinePrice}",
      "final_price": "${finalPrice}",
      "discount_allocations": [
          {
              "amount": "${discountAllocationAmount1}",
              "discount_application": "${discountApplication1}"
          },
          {
              "amount": "${discountAllocationAmount2}",
              "discount_application": "${discountApplication2}"
          }
      ]
  }`;
    if (i < lineItems.length - 1) ret += `,`;
  }

  const cartLevelDiscountApplicationAmount1 = Math.ceil(Math.random() * 10);
  const cartLevelDiscountApplicationTitle1 = `-${cartLevelDiscountApplicationAmount1}€`;
  const cartLevelDiscountApplicationAmount2 = Math.ceil(Math.random() * 10);
  const cartLevelDiscountApplicationTitle2 = `BOOSTER(-${cartLevelDiscountApplicationAmount2})`;
  const totalDiscount =
    Number(cartLevelDiscountApplicationAmount1) + Number(cartLevelDiscountApplicationAmount2);

  ret += ` ],
      "cart": {
        "item_count": ${lineItems.length},
        "compare_at_price": "${Number(cartCompareAtPrice).toFixed(2)}",
        "original_total_price": "${Number(cartOriginalCompareAtPrice).toFixed(2)}",
        "items_subtotal_price": "${Number(cartItemsSubtotalPrice).toFixed(2)}",
        "total_discount": "${totalDiscount}",
        "total_fidelity_discount": "20",
        "total_discount_with_compare_at": "${(
          Number(cartCompareAtPrice) - Number(cartItemsSubtotalPrice)
        ).toFixed(2)}",
        "total_price": "${Number(cartItemsSubtotalPrice - totalDiscount).toFixed(2)}",
        "cart_level_discount_applications": [
          {
              "title": "${cartLevelDiscountApplicationTitle1}",
              "total_allocated_amount": "${cartLevelDiscountApplicationAmount1}"
          },
          {
              "title": "${cartLevelDiscountApplicationTitle2}",
              "total_allocated_amount": "${cartLevelDiscountApplicationAmount2}"
          }
        ]
      },
      "consumed_rules": [],
      "consumed_feeds": [],
      "consumed_fidelity":["FIDMOCK0${('0' + Math.ceil(Math.random() * 100)).slice(
        -2,
      )}", "FIDMOCK0${('0' + Math.ceil(Math.random() * 100)).slice(-2)}"],
      "coupon": [],
      "consumed_coupons": [`;

  const totalValidCouponsCount =
    coupons?.filter((coupon) => coupon == 'test' || coupon == 'test2').length || 0;
  var couponCounter = 0;

  coupons?.forEach((coupon) => {
    if (coupon == 'test' || coupon === 'test2') {
      couponCounter++;
      ret += `{
        "Coupon": {
            "code": "${coupon}",
            "contextName": "${coupon}"
        }
      }`;
      if (couponCounter < totalValidCouponsCount) ret += ',';
    }
  });

  ret += '], "coupon": [';

  var couponCounter = 0;
  coupons?.forEach((coupon) => {
    if (coupon == 'test' || coupon === 'test2') {
      couponCounter++;
      ret += `{
          "name": "${coupon}",
          "code": "${coupon}",
          "isValid": true
      }`;
      if (couponCounter < totalValidCouponsCount) ret += ',';
    }
  });

  ret += ']}';

  clDebug(`*** (getMockCartApiReturn) :: res: ${ret}`);

  return JSON.parse(ret);
}
