export function updateDataLayer(data) {
  if (data === undefined) return false;

  window.vpDataLayer = window.vpDataLayer || {
    handleMap: {},
    idMap: {},
    cart: {},
  };

  let eventDataType = 'generic';

  if (data.cart) {
    window.vpDataLayer.cart = { ...data };
    eventDataType = 'cart';
  }

  if (data.products_prices && data.products_prices.length > 0) {
    data.products_prices.forEach((product) => {
      window.vpDataLayer.handleMap[product.product_handle] = { ...product };
    });
    eventDataType = 'handles';
  }

  if (data.variants_prices && data.variants_prices.length > 0) {
    data.variants_prices.forEach((variant) => {
      window.vpDataLayer.idMap[variant.variant_id] = { ...variant };
    });
    eventDataType = 'variant_ids';
  }

  const vpDataUpdated = new CustomEvent('vp-data-layer-updated', {
    detail: {
      newVpData: { ...data },
      dataType: eventDataType,
    },
  });

  document.dispatchEvent(vpDataUpdated);

  return true;
}
