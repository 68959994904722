import { getCartPromotionsAndPopulate } from './apiCalls';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './utils/localStorage';

const INITIALISED_FLAG_CLASS = 'vp-initialised';
const ADD_FIDELITY_SUBMIT_BTN_CLASS = 'vp-add-fidelity';

const FIDELITY_LOCAL_STORAGE_KEY = 'vp-fidelity-coupon';
const COUPON_LOCAL_STORAGE_KEY = 'vp-applied-coupons';

// SELECT_NEW_COUPON
const UNINITIALISED_SUBMIT_BTN_SELECTOR = `.vp-fidelity-submit:not(.${INITIALISED_FLAG_CLASS})`;

// Use sharedData to avoid callback hell
const sharedData = {
  populateCartPromotions: null,
  shopName: null,
  lineItems: null,
};

export function initFidelityCouponForm(data, populateCartPromotions, shopName, lineItems) {
  const { coupons: coupons } = data;

  if (!coupons || !coupons.length) {
    const selectElems = document.querySelectorAll('.vp-fidelity-select-container');
    selectElems.forEach((selectElem) => (selectElem.style.display = 'none'));
    return;
  }

  sharedData.populateCartPromotions = populateCartPromotions;
  sharedData.shopName = shopName;
  sharedData.lineItems = lineItems;
  sharedData.coupons = coupons;

  toggleSelectFidelityDisplay();
  initialiseFidelitySubmitButtons();
}

function initialiseFidelitySubmitButtons() {
  const fidelitySubmitBtn = document.querySelectorAll(UNINITIALISED_SUBMIT_BTN_SELECTOR);

  if (fidelitySubmitBtn) {
    fidelitySubmitBtn.forEach((btn) => {
      const isAddButton = btn.classList.contains(ADD_FIDELITY_SUBMIT_BTN_CLASS);

      btn.addEventListener('click', () => {
        submitFidelityCoupon(isAddButton);
      });

      btn.classList.add(INITIALISED_FLAG_CLASS);
    });
  }
}

function submitFidelityCoupon(isAddButton) {
  // if we clicked on the add button => get the oldest fidelity coupon available
  // if we clicked on the remove button => return null (and remove the localstorage)
  const mostRecentFidelityCoupon = isAddButton ? sharedData.coupons[0].coupon : null;
  const couponsLocalStorage = getLocalStorageItem(COUPON_LOCAL_STORAGE_KEY);

  getCartPromotionsAndPopulate(
    (data) => {
      sharedData.populateCartPromotions(data);
      rerenderFidelity(mostRecentFidelityCoupon);
    },
    sharedData.shopName,
    sharedData.lineItems,
    couponsLocalStorage,
    mostRecentFidelityCoupon,
  );
}

function rerenderFidelity(fidelityCoupon = null) {
  // Prices will be re-rendered later on
  document.querySelectorAll('.vp-price-changed').forEach((el) => {
    el.classList.remove('vp-price-changed');
  });

  if (fidelityCoupon) {
    setFidelityInLocalStorage(fidelityCoupon); // TODO: ByMax first or last to be the most recent
  } else {
    removeFidelityFromLocalStorage();
  }
  toggleSelectFidelityDisplay();

  document.dispatchEvent(new Event('vp-rerender'));
}

function toggleSelectFidelityDisplay() {
  const fidelityContainer = document.querySelectorAll('.vp-fidelity-select-container');
  const selectAvailableElems = document.querySelectorAll('.vp-fidelity-select-available');
  const selectUsedElems = document.querySelectorAll('.vp-fidelity-select-used');

  const fidelityLocalStorage = getFidelityCouponFromLocalStorage();

  if (!fidelityLocalStorage) {
    selectAvailableElems.forEach(
      (selectAvailableElem) => (selectAvailableElem.style.display = 'block'),
    );
    selectUsedElems.forEach((selectUsedElem) => (selectUsedElem.style.display = 'none'));
    fidelityContainer.forEach((container) => (container.style.display = 'block'));
  } else if (selectUsedElems.length) {
    selectAvailableElems.forEach(
      (selectAvailableElem) => (selectAvailableElem.style.display = 'none'),
    );
    selectUsedElems.forEach((selectUsedElem) => (selectUsedElem.style.display = 'block'));
    fidelityContainer.forEach((container) => (container.style.display = 'block'));
  } else {
    fidelityContainer.forEach((container) => (container.style.display = 'none'));
  }
}

export function getFidelityCouponFromLocalStorage() {
  return getLocalStorageItem(FIDELITY_LOCAL_STORAGE_KEY);
}

function setFidelityInLocalStorage(fidelityCouponReference) {
  setLocalStorageItem(FIDELITY_LOCAL_STORAGE_KEY, fidelityCouponReference);
}

export function removeFidelityFromLocalStorage() {
  removeLocalStorageItem(FIDELITY_LOCAL_STORAGE_KEY);
}
