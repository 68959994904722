const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

export function getLocalStorageItem(key) {
  var todaysDate = new Date();

  const item = window.localStorage.getItem(key);
  if (item) {
    const parsedItem = JSON.parse(item);
    const itemCreationDate = Date.parse(parsedItem.created_on);
    if (todaysDate.valueOf() - itemCreationDate.valueOf() > MILLISECONDS_PER_DAY * 2) {
      removeLocalStorageItem(key);
    } else {
      return parsedItem.value;
    }
  }
  return null;
}

export function setLocalStorageItem(key, value) {
  window.localStorage.setItem(
    key,
    JSON.stringify({
      value: value,
      created_on: new Date(),
    }),
  );
}

export function replaceLocalStorageItem(key, newValue) {
  if (newValue.length == 0) {
    removeLocalStorageItem(key);
  } else {
    window.localStorage[key] = JSON.stringify({
      value: newValue,
      created_on: new Date(),
    });
  }
}

export function removeLocalStorageItem(key) {
  window.localStorage.removeItem(key);
}
