import { clDebug, initOnScroll, pollFor } from './utils/utils';
import { populatePriceTemplate, populateLineItemTemplate, populateCartTotals } from './rendering';
import {
  getRequestBody,
  getPricesAndPopulate,
  createDraftOrder,
  getCartPromotionsAndPopulate,
  getFidelityCouponsAndPopulate,
  getVPMetadata,
  getCartContent,
  updateCartContent,
  changeCartContent,
  getOzCart,
} from './apiCalls';
import {
  getCouponsFromLocalStorage,
  initCouponsForm,
  initRemoveCoupon,
  populateCouponFeedback,
  removeAllCouponsFromLocalStorage,
} from './coupons';
import {
  getFidelityCouponFromLocalStorage,
  initFidelityCouponForm,
  removeFidelityFromLocalStorage,
} from './fidelity';

// --- GENERIC NAMES FOR CLASSES AND ATTRIBUTES ---
const PRICE_CHANGED_CLASS = 'vp-price-changed';

// SELECTORS
const ID_DATA_ATTR = 'data-vp-id';
const QTY_DATA_ATTR = 'data-vp-qty';
const SELECTOR_WITH_ID = `[${ID_DATA_ATTR}]:not(.${PRICE_CHANGED_CLASS})`;
// CHECKOUT EXTENSIBILITY VARS
const USE_AKI_FUNCTIONS = getVPMetadata?.privateSales?.useAkiFunctions || false;
const CART_UPDATE_EVENT_NAME = getVPMetadata?.privateSales?.cartUpdateEventName || '';

// ------------------------------------------------

/**
 * Initialise VP on IDs
 */
export function runVPOnIds(shopName, moneyFormat) {
  // STEP 1
  pollFor(SELECTOR_WITH_ID, run);

  // STEP 2
  function run($e) {
    const $products = !$e || !$e.length ? $(SELECTOR_WITH_ID) : $e;

    // First prices transformation
    getProductPrices($products);

    // After scrolling, second prices transformation
    initOnScroll(() => {
      getProductPrices($(SELECTOR_WITH_ID));
    });
  }

  // STEP 3
  function getProductPrices($products) {
    const productVariants = [];
    const lineItems = [];

    $products.each((_i, product) => {
      try {
        const $product = $(product);
        $product.addClass(PRICE_CHANGED_CLASS);
        const productVariant = $product.attr(ID_DATA_ATTR);

        if (productVariant) {
          const quantity = Number($product.attr(QTY_DATA_ATTR));
          if (quantity !== undefined) productVariants.push(productVariant);
          if (
            quantity > 0 &&
            !lineItems.find(
              (lineItem) =>
                lineItem.variant_id === productVariant && lineItem.quantity === quantity,
            )
          )
            lineItems.push({ variant_id: productVariant, quantity: quantity });
        }
      } catch (error) {}
    });

    if (productVariants.length) {
      getPricesAndPopulate(populatePrices, shopName, productVariants, false);
    }
    if (lineItems.length) {
      const couponsLocalStorage = getCouponsFromLocalStorage();
      const fidelityCouponLocalStorage = getFidelityCouponFromLocalStorage();
      clDebug(`*** (getProductPrices) :: couponsLocalStorage: ${couponsLocalStorage}`);
      clDebug(
        `*** (getProductPrices) :: fidelityCouponLocalStorage: ${fidelityCouponLocalStorage}`,
      );
      initCouponsForm(populateCartPromotions, shopName, lineItems);
      getFidelityCouponsAndPopulate((data) => {
        initFidelityCouponForm(data, populateCartPromotions, shopName, lineItems);
      }, shopName);
      getCartPromotionsAndPopulate(
        populateCartPromotions,
        shopName,
        lineItems,
        couponsLocalStorage,
        fidelityCouponLocalStorage,
      );
      // handle cart submit btn
      USE_AKI_FUNCTIONS ? setAkiFunctionAttributes() : initDraftOrder(lineItems);
    }
  }

  function populateCartPromotions(data) {
    const apiLineItems = data.line_items;
    const apiCart = data.cart;
    const apiConsumedCoupons = data.consumed_coupons;
    const apiCoupons = data.coupon;

    if (!apiLineItems || !apiCart) {
      return;
    }

    // Populate coupon feedback
    populateCouponFeedback(apiConsumedCoupons, apiCoupons);

    // Line items
    apiLineItems.forEach(
      ({
        variant_id,
        quantity,
        line_compare_at_price,
        compare_at_price,
        original_line_price,
        original_price,
        final_line_price,
        final_price,
      }) => {
        const $lineItems = $(`[${QTY_DATA_ATTR}][${ID_DATA_ATTR}="${variant_id}"]`);
        $lineItems.each(function () {
          const $lineItem = $(this);
          populateLineItemTemplate(
            $lineItem,
            line_compare_at_price,
            compare_at_price,
            final_line_price,
            final_price,
            moneyFormat,
            apiLineItems,
            variant_id,
          );
        });
      },
    );

    // Cart summary
    populateCartTotals(apiCart, apiConsumedCoupons, moneyFormat);

    // Coupons
    initRemoveCoupon();
  }

  // STEP 4
  function populatePrices(data) {
    const productPrices = data.variants_prices;
    if (!productPrices) {
      return;
    }
    productPrices.forEach(({ compare_at_price, price, variant_id }) => {
      const $products = $(`[${ID_DATA_ATTR}="${variant_id}"]`);
      $products.each(function () {
        const $product = $(this);
        if ($product.attr(QTY_DATA_ATTR) === undefined)
          populatePriceTemplate($product, compare_at_price, price, moneyFormat);
      });
    });
  }

  /**
   * Initialise draft order (when user goes to checkout)
   * On cart page and mini cart
   */
  function initDraftOrder(lineItems) {
    pollFor('.vp-checkout-btn', ($submitBtn) => {
      $submitBtn.off('click').on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const couponsLocalStorage = getCouponsFromLocalStorage();
        removeAllCouponsFromLocalStorage();
        const fidelityCouponLocalStorage = getFidelityCouponFromLocalStorage();
        removeFidelityFromLocalStorage();
        const body = getRequestBody(
          lineItems,
          false,
          false,
          true,
          couponsLocalStorage,
          fidelityCouponLocalStorage,
        );
        createDraftOrder(
          body,
          shopName,
          ({ location }) => {
            if (location) {
              const locale = window.__ozjs?.privateSales?.language?.locale;
              const locationWithLocale = location + '?locale=' + locale;
              const url = locale ? locationWithLocale : location;
              window.location.href = url;
            }
          },
          () => {
            console.error('[Aki discount] error draftorder');
            // in case of error don't block the order, submit the original cart form
            $submitBtn.closest('form').submit();
          },
        );
      });
    });
  }

  // CHECKOUT EXTENSIBILITY Update cart attributes
  function setAkiFunctionAttributes() {
    pollFor('.vp-checkout-btn', ($submitBtn) => {
      $submitBtn.off('click').on('click', async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const currentShopifyCart = await getCartContent();
        const currentCart = await getOzCart(currentShopifyCart);

        // on envoie un cart "amaigri" pour économiser les ressources fonctions
        const { cart_level_discount_applications, items, aki_coupons } = currentCart;

        // on envoie un cart "amaigri" pour économiser les ressources fonctions
        const functionCart = {
          original_total_price: currentShopifyCart.total_price,
          cart_level_discount_applications: cart_level_discount_applications.map(
            ({ amount, discount_application }) => ({
              amount: parseInt((amount * 100).toFixed()),
              discount_application: {
                total_allocated_amount: parseInt(
                  (discount_application.total_allocated_amount * 100).toFixed(),
                ),
                title: discount_application.title,
              },
            }),
          ),
          aki_coupons,
          items: items.map(
            ({
              original_id,
              final_price,
              line_level_discount_allocations,
              variant_id,
              is_goody,
              original_price,
              handle,
              quantity,
            }) => {
              return {
                original_id,
                line_level_total_discount:
                  (parseInt((original_price * 100).toFixed()) -
                    parseInt((final_price * 100).toFixed())) *
                  quantity, //hack : on envoie la différence pour régler les problèmes d'arrondis
                line_level_discount_allocations: line_level_discount_allocations.map(
                  ({ amount, discount_application }) => ({
                    amount: parseInt((amount * 100).toFixed()),
                    discount_application: {
                      total_allocated_amount: parseInt(
                        (discount_application.total_allocated_amount * 100).toFixed(),
                      ),
                      title: discount_application.title,
                    },
                  }),
                ),
                variant_id,
                is_goody,
                original_price: parseInt((original_price * 100).toFixed()),
                handle,
                quantity,
              };
            },
          ),
        };
        const updatedCartContent = await updateCartContent(
          JSON.stringify({
            attributes: { aki_discounts: JSON.stringify(functionCart) },
          }),
        );
        // console.log('Updated cart content: ', updatedCartContent);

        // submit form
        $submitBtn.closest('form').submit();
      });
    });
  }
}

// CHECKOUT EXTENSIBILITY
export async function removeGoodiesFromCart() {
  // do nothing if checkout extensibility mode is off
  if (!USE_AKI_FUNCTIONS) return null;
  // Remove AKI goodies from the cart
  let currentCart = await getCartContent();

  // Supprime les attributs palcés par les functions
  if (currentCart?.attributes?.aki_discounts) {
    currentCart = await updateCartContent(JSON.stringify({ attributes: { aki_discounts: null } }));
  }

  //nettoyage des goodies
  let hadGoodies = false;
  let needTest = !!currentCart.items.length;
  while (needTest) {
    const goodyIndex = currentCart.items.findIndex(
      (item) =>
        item?.properties?.['aki-discount-goody'] === 'true' ||
        item?.properties?.['_aki-discount-goody'] === 'true',
    );
    const goody = currentCart.items[goodyIndex];
    if (goody) {
      currentCart = await changeCartContent(
        JSON.stringify({
          line: goodyIndex + 1,
          quantity: 0,
        }),
      );
      needTest = !!currentCart.items.length;
      hadGoodies = true;
    } else {
      needTest = false;
    }
  }

  if (hadGoodies) {
    // try to rerender the cart items
    // Emit a site-wide event to re-render the cart/cart-drawer
    if (CART_UPDATE_EVENT_NAME && CART_UPDATE_EVENT_NAME.length > 0) {
      document.dispatchEvent(
        new CustomEvent(CART_UPDATE_EVENT_NAME, {
          detail: {
            shopifyCart: currentCart,
          },
        }),
      );
    } else {
      // reload the page to remove deleted goodies from the page
      window.location.reload();
    }
  }

  return currentCart;
}
