import { getCartPromotionsAndPopulate } from './apiCalls';
import {
  emptyCouponFeedback,
  populateAlreadyUsedCouponFeedback,
  populateInvalidCouponFeedback,
  populateInvalidCouponForCartContentFeedback,
  populateSuccessfullyAddedCouponFeedback,
} from './rendering';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  replaceLocalStorageItem,
} from './utils/localStorage';

const COUPONS_INPUT_SELECTOR = '.vp-coupons-input';
const COUPONS_SUBMIT_SELECTOR = '.vp-coupons-submit';

const INITIALISED_FLAG_CLASS = 'vp-initialised';

// COUPON LOCAL STORAGE KEY
const COUPON_LOCAL_STORAGE_KEY = 'vp-applied-coupons';
const FIDELITY_LOCAL_STORAGE_KEY = 'vp-fidelity-coupon';

export function initRemoveCoupon() {
  const coupons = document.querySelectorAll(
    `.vp-prices-coupon-tag:not(.${INITIALISED_FLAG_CLASS})`,
  );
  coupons.forEach((coupon) => {
    coupon.addEventListener('click', () => {
      const couponValue = coupon.getAttribute('data-value');
      const currentLocalStorageCoupons = getLocalStorageItem(COUPON_LOCAL_STORAGE_KEY);

      const index = currentLocalStorageCoupons.indexOf(couponValue);

      if (index > -1) {
        coupon.remove();
        emptyCouponFeedback();
        currentLocalStorageCoupons.splice(index, 1);
        replaceLocalStorageItem(COUPON_LOCAL_STORAGE_KEY, currentLocalStorageCoupons);
        document.querySelectorAll('.vp-price-changed').forEach((el) => {
          el.classList.remove('vp-price-changed');
        });
        document.dispatchEvent(new Event('vp-rerender'));
      }
    });
    coupon.classList.add(INITIALISED_FLAG_CLASS);
  });
}

export function populateCouponFeedback(apiConsumedCoupons, apiCoupons) {
  const couponInput = document.querySelector(COUPONS_INPUT_SELECTOR);
  const couponInputValue = couponInput?.value.trim() || '';

  const appliedCouponsLocalStorage = getCouponsFromLocalStorage();
  const newValidCoupons = [];

  if (couponInputValue != '') {
    var isValidCoupon = 0;
    apiConsumedCoupons.forEach(({ Coupon }) => {
      newValidCoupons.push(Coupon.code);
      if (couponInputValue === Coupon.code) {
        isValidCoupon++;
      }
    });

    if (isValidCoupon > 0) {
      if (appliedCouponsLocalStorage?.filter((coupon) => coupon === couponInputValue).length > 0) {
        populateAlreadyUsedCouponFeedback(couponInputValue);
      } else {
        populateSuccessfullyAddedCouponFeedback(couponInputValue);
        // Prices will be re-rendered later on
        document.querySelectorAll('.vp-price-changed').forEach((el) => {
          el.classList.remove('vp-price-changed');
        });
      }
    } else {
      if (apiCoupons?.filter((coupon) => coupon.code === couponInputValue).length > 0) {
        populateInvalidCouponForCartContentFeedback(couponInputValue);
      } else {
        populateInvalidCouponFeedback(couponInputValue);
      }
    }
    replaceLocalStorageItem(COUPON_LOCAL_STORAGE_KEY, newValidCoupons);
    couponInput.value = '';
  }
}

export function initCouponsForm(populateCartPromotions, shopName, lineItems) {
  const couponSubmitBtn = document.querySelector(COUPONS_SUBMIT_SELECTOR);
  const couponInput = document.querySelector(COUPONS_INPUT_SELECTOR);

  if (
    couponInput &&
    couponSubmitBtn &&
    !couponSubmitBtn.classList.contains(INITIALISED_FLAG_CLASS)
  ) {
    couponSubmitBtn.addEventListener('click', () => {
      const couponsLocalStorage = getCouponsFromLocalStorage() || [];
      const couponInputValue = couponInput.value;

      const fidelityCouponLocalStorage = getLocalStorageItem(FIDELITY_LOCAL_STORAGE_KEY);

      if (couponInputValue !== '') {
        if (!couponsLocalStorage.filter((coupon) => coupon === couponInputValue).length > 0) {
          couponsLocalStorage.push(couponInputValue);
        }
        getCartPromotionsAndPopulate(
          populateCartPromotions,
          shopName,
          lineItems,
          couponsLocalStorage,
          fidelityCouponLocalStorage,
        );
      }
    });
    couponSubmitBtn.classList.add(INITIALISED_FLAG_CLASS);
  }
}

export function getCouponsFromLocalStorage() {
  return getLocalStorageItem(COUPON_LOCAL_STORAGE_KEY);
}

export function removeAllCouponsFromLocalStorage() {
  return removeLocalStorageItem(COUPON_LOCAL_STORAGE_KEY);
}
