import {
  getMockApiReturnFromVariants,
  getMockApiReturnFromHandles,
  getMockCartApiReturn,
  getMockFidelityCouponsApi,
} from './mocks';
import { getCouponsFromLocalStorage } from './coupons';
import { updateDataLayer } from './utils/dataLayer';

export const getVPMetadata = (() => {
  return window.__ozjs || {};
})();

const TEST_MODE = window?.__ozjs?.privateSales?.testMode || false;

const API_URL = getVPMetadata?.privateSales?.url;
const USER_ID = getVPMetadata?.customerId;
// CHECKOUT EXTENSIBILITY VARS
const CART_URL = window.Shopify.routes.root + 'cart.js';
const CART_UPDATE_URL = window.Shopify.routes.root + 'cart/update.js';
const CART_CHANGE_URL = window.Shopify.routes.root + 'cart/change.js';

// --- API Request URLS ---

const getPricesAPIRequestURL = (shopName) => {
  return API_URL ? `${API_URL}/theoz/vp/prices?shop=${shopName}` : '';
};

const getFidelityCouponsAPIRequestURL = (shopName) => {
  return API_URL && USER_ID
    ? `${API_URL}/theoz/fid/coupons?shop=${shopName}&userId=${USER_ID}`
    : '';
};

const getCartAPIRequestURL = (shopName) => {
  // with checkout extensibility changes we migrated from v2 to v3 API URL
  // we need force amounts with decimals to be compatible with other old api
  return API_URL ? `${API_URL}/theoz/vp/v3/cart?shop=${shopName}&amounts_with_decimals=true` : '';
};

const getDraftOrderAPIRequestURL = (shopName) => {
  return API_URL ? `${API_URL}/theoz/vp/order?shop=${shopName}` : '';
};

function populateCartErrorMessage() {
  if (window.Shopify?.theme?.role != 'main') {
    $('.vp-error-message').remove('');
    $('.vp-error-message').append(
      '<b class="vp-error-msg" style="display: block; padding: 20px 0;">Nous rencontrons des problèmes avec les ventes privées. Veuillez contacter notre service client si le problème persiste.</b>',
    );
  }
  console.error('[Aki Discount] /cart is down');
  // hide coupon code input if aki is down
  $('.vp-coupons').hide();
}

// --- API Requests ---

/*
 * Returns request body with appropriate handles / product variants
 * By default it takes handles as param
 */
export function getRequestBody(
  properties,
  hasVariants,
  hasHandles,
  hasLineItems = false,
  coupons = [],
  fidelityCoupon = '',
) {
  if (properties && properties.length) {
    return {
      user_id: getVPMetadata && getVPMetadata.customerId,
      date: new Date().toISOString(),
      ...(coupons?.length && { discount_code: coupons }),
      ...(fidelityCoupon && { fidelity_code: [fidelityCoupon] }),
      ...(hasHandles && { products_handle: properties }),
      ...(hasVariants && { variants_id: properties }),
      ...(hasLineItems && { line_items: properties }),
    };
  }
}

export function createDraftOrder(body, shopName, successCb, errorCb) {
  $.ajax({
    type: 'POST',
    url: getDraftOrderAPIRequestURL(shopName),
    data: JSON.stringify(body),
    success: successCb,
    error: errorCb,
    timeout: 5000,
  });
}

export function getNewPrices(body, shopName, cb) {
  $.ajax({
    type: 'POST',
    url: getPricesAPIRequestURL(shopName),
    data: JSON.stringify(body),
    success: [cb, updateDataLayer],
    error: () => {
      console.error('[Aki Discount] /prices is down');
    },
    timeout: 5000,
  });
}

export function getFidelityCoupons(shopName, cb) {
  $.ajax({
    type: 'GET',
    url: getFidelityCouponsAPIRequestURL(shopName),
    success: cb,
    error: () => {
      console.error('[Aki Discount] /coupons is down');
    },
    timeout: 5000,
  });
}

export function getCartPromotions(body, shopName, successCb) {
  $.ajax({
    type: 'POST',
    url: getCartAPIRequestURL(shopName),
    data: JSON.stringify(body),
    success: [successCb, updateDataLayer],
    error: populateCartErrorMessage,
    timeout: 5000,
  });
}

// --- Get data and populate it ---

export function getPricesAndPopulate(cb, shopName, productReferences, isHandle) {
  const uniqueProdRef = [...new Set(productReferences)];

  if (!TEST_MODE) {
    const body = getRequestBody(uniqueProdRef, !isHandle, isHandle);
    if (body) getNewPrices(body, shopName, cb);
  } else {
    let mockData;
    if (isHandle) {
      mockData = getMockApiReturnFromHandles(uniqueProdRef);
    } else {
      mockData = getMockApiReturnFromVariants(uniqueProdRef);
    }
    cb(mockData);
    // update vp data layer with mock data as well
    updateDataLayer(mockData);
  }
}

export function getFidelityCouponsAndPopulate(cb, shopName) {
  if (!TEST_MODE) {
    getFidelityCoupons(shopName, cb);
  } else {
    cb(getMockFidelityCouponsApi());
  }
}

export function getCartPromotionsAndPopulate(cb, shopName, lineItems, coupons, fidelityCoupons) {
  const body = getRequestBody(lineItems, false, false, true, coupons, fidelityCoupons);
  if (!TEST_MODE) {
    if (body) getCartPromotions(body, shopName, cb);
  } else {
    const mockData = getMockCartApiReturn(lineItems, coupons);
    cb(mockData);
    // update vp data layer with mock data as well
    updateDataLayer(mockData);
  }
}

// CHECKOUT EXTENSIBILITY FUNCTIONS

export async function getCartContent() {
  try {
    const result = await fetch(CART_URL);
    return result.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateCartContent(body) {
  try {
    const result = await fetch(CART_UPDATE_URL, {
      body,
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function changeCartContent(body) {
  try {
    const result = await fetch(CART_CHANGE_URL, {
      body,
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response.json();
}

export async function getAkiCart(payload) {
  const shopName = window.Shopify.shop;
  let url = getCartAPIRequestURL(shopName);

  try {
    const result = await fetchWithTimeout(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: payload,
      timeout: 8000,
    });
    return result;
  } catch (error) {
    console.error(error);
    return { error: true };
  }
}

export async function getOzCart(shopifyCart) {
  let currentCart = shopifyCart;

  const current_line_items = (currentCart.items || []).map(
    ({ variant_id, quantity, id, final_price: price, compare_at_price, ...rest }) => {
      return {
        id: id.toString(),
        variant_id,
        quantity,
        price,
        compare_at_price,
      };
    },
  );

  if (!current_line_items.length) return currentCart;

  const request = {
    date: new Date().toISOString(),
    line_items: current_line_items,
    //  localization: this.localization,
  };

  // Rajoute les coupons depuis le local storage
  const discount_code = getCouponsFromLocalStorage(); //getActiveAkiCoupons();
  request.discount_code = discount_code;

  //const user = getUser();
  const userId = getVPMetadata?.customerId;
  if (userId) request.user_id = userId;

  const { cart, consumed_coupons, coupon, line_items, error } = await getAkiCart(
    JSON.stringify(request),
  );

  if (error) {
    console.error("[App Oz : AKI discount] endpoint '/cart' is down");
    return currentCart;
  }

  const uniqueIds = new Map();
  const modifiedItems = line_items.map((item, key) => {
    // récupération des produits d'orgine dans le panier
    const nativeData =
      item.is_goody !== true
        ? currentCart.items.find((nativeItem) => nativeItem.id.toString() === item.id.toString()) ||
          {}
        : {};

    const ShopifyCartData = JSON.parse(JSON.stringify(nativeData));

    const object = {
      ...ShopifyCartData, // La copie de l'objet Shopify d'origine
      ...item, // La surcharge provenant d'AKi discount
      discounted_price: item.final_price, // et le remplacement du discounted_price par le final_price
      properties: {
        ...ShopifyCartData.properties,
        ...item.properties,
      },
    };

    //*******gestion des splits
    const idx = uniqueIds.get(item.id);
    if (idx) {
      object.original_id = item.id;
      object.id = `${item.id}-${idx}`;
      uniqueIds.set(item.id, idx + 1);
    } else {
      uniqueIds.set(item.id, 1);
    }

    return object;
  });

  const modifiedCart = {
    ...currentCart,
    items: modifiedItems, // surcharge des itms
    ...cart, // surcharge du panier
    aki_all_coupons: coupon,
    aki_coupons: userId //this.userIsLoggedIn
      ? consumed_coupons.map(({ Coupon }) => Coupon) // info coupon validée
      : coupon.filter((elem) => elem.isValid), // info coupon peu controlée
  };

  // on retourne les données modifiées
  return modifiedCart;
}
