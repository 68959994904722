import { formatMoney } from './utils/moneyUtils';

const COUPONS_FEEDBACK_SELECTOR = '.vp-coupons-feedback';

const ABSOLUTE_DISCOUNT_CLASS = 'vp-prices-absolute-discount';

const translations = window.__ozjs?.privateSales?.translations;

const TRANSLATIONS_OBJECT = {
  INVALID_COUPON: translations?.invalidCoupon,
  INVALID_CART_FOR_COUPON: translations?.invalidCartForCoupon,
  CUSTOMER_FOR_COUPON: translations?.customerForCoupon,
  COUPON_SUCCESSFULLY_ADDED: translations?.couponSuccessfullyAdded,
  COUPON_ALREADY_USED: translations?.couponAlreadyUsed,
  PRICE_FROM: translations?.priceFrom,
  DELIVERY_INFO_FREE: translations?.deliveryInfoFree,
  DELIVERY_INFO_FREE_SHIPPING: translations?.deliveryInfoFreeShipping,
  DELIVERY_INFO_AVAILABLE: translations?.deliveryInfoAvailsable,
};

function decodeTranslate(str) {
  if (str === undefined) {
    return str;
  }
  return str.replace(/&#39;/g, "'");
}

function decodeTranslateLoop(object) {
  for (const p in object) {
    const decoded = decodeTranslate(object[p]);
    object[p] = decoded;
  }
  return object;
}

const TRANSLATIONS = decodeTranslateLoop(TRANSLATIONS_OBJECT);

function updateDeliveryMessage(moneyFormat) {
  const cartTotalMoney = $('.vp-cart-totals .vp-cart-total').text();
  const cartTotal = parseInt(cartTotalMoney);
  const getAmount = $('.cart_shipping.js-cart_shipping').data('free-from');
  const amountMoney = formatMoney(getAmount, moneyFormat);
  const amount = parseInt(amountMoney);
  $('.vp-cart-original-shipping').hide();
  $('.vp-cart-shipping').show();

  if (cartTotal >= amount) {
    $('.vp-cart-shipping span').text(TRANSLATIONS.DELIVERY_INFO_FREE);
  } else if (cartTotal > 0) {
    const formatCartTotal = parseInt(cartTotal.toFixed(2).replace('.', ''));
    const newAmount = getAmount - formatCartTotal;
    const lastAmount = formatMoney(newAmount, moneyFormat);
    const delivery_info_free_shipping = TRANSLATIONS.DELIVERY_INFO_FREE_SHIPPING?.replace(
      '{{ amount }}',
      lastAmount,
    );
    $('.vp-cart-shipping span').text(delivery_info_free_shipping);
  } else {
    const delivery_info_free_avaible = TRANSLATIONS.DELIVERY_INFO_AVAILABLE?.replace(
      '{{ amount }}',
      amountMoney,
    );
    $('.vp-cart-shipping span').text(delivery_info_free_avaible);
  }
}

export const populateCartTotals = (apiCart, apiConsumedCoupons, moneyFormat) => {
  const totalPriceFormatted = formatMoney(apiCart.total_price, moneyFormat);
  const totalDiscountWithCompareAt = apiCart.total_discount_with_compare_at;
  const totalDiscount = apiCart.total_discount;
  const compareAtPriceFormatted = formatMoney(apiCart.compare_at_price, moneyFormat);
  const cartLevelDiscountApplications = apiCart.cart_level_discount_applications;
  const $vpCartContainer = $('.vp-cart-total-container');
  const $vpCartContainerDiscounted = $('.vp-cart-total-container .discounted');
  const $vpCouponsContainer = $('.vp-coupons');

  $('.vp-cart-total').text(totalPriceFormatted);
  if (translations) {
    updateDeliveryMessage(moneyFormat);
  }

  if (totalDiscountWithCompareAt !== '0.00') {
    $vpCartContainerDiscounted.show();
    $('.vp-cart-total-discount-with-compare-at').text(
      formatMoney(totalDiscountWithCompareAt, moneyFormat),
    );
  } else {
    $vpCartContainerDiscounted.hide();
    $('.vp-cart-total-discount-with-compare-at').html('');
  }
  if (totalDiscount !== '0.00') {
    $('.vp-cart-total-discount').text(formatMoney(totalDiscount, moneyFormat));
  } else {
    $('.vp-cart-total-discount').html('');
  }
  if (compareAtPriceFormatted != totalPriceFormatted) {
    $('.vp-cart-compare-at').text(compareAtPriceFormatted);
  } else {
    $('.vp-cart-compare-at').html('');
  }
  $vpCartContainer.show();
  $vpCouponsContainer.show();
  $('.vp-cart-original-total').hide();

  const $currentlyDisplayedDiscounts = $vpCartContainer.find('.vp-prices-discount-tags');
  const $currentlyDisplayedCoupons = $vpCartContainer.find('.vp-prices-coupon-tags');
  $currentlyDisplayedCoupons.html('');
  $currentlyDisplayedDiscounts.html('');

  // Cart level discount applications

  if (cartLevelDiscountApplications) {
    cartLevelDiscountApplications.forEach(({ title }) => {
      if (
        title &&
        $currentlyDisplayedDiscounts.find(`.vp-prices-discount-tag:contains("${title}")`).length ==
          0
      )
        $currentlyDisplayedDiscounts.append(`<span class="vp-prices-discount-tag">${title}</span>`);
    });
  }

  // Consumed coupons todo -- confirm they don't need to be displayed
  if (apiConsumedCoupons) {
    apiConsumedCoupons.forEach(({ Coupon }) => {
      const couponCode = Coupon.code;
      if (
        couponCode &&
        $currentlyDisplayedCoupons.find(`.vp-prices-coupon-tag[data-value="${couponCode}"]`)
          .length == 0
      ) {
        $currentlyDisplayedCoupons.append(
          `<button type="button" class="vp-prices-coupon-tag vp-prices-coupons" data-value="${couponCode}"><span>X</span> ${couponCode}</button>`,
        );
      }
    });
  }
};

export const populatePriceTemplate = (
  $product,
  max,
  min,
  moneyFormat,
  quantity = 1,
  minPriceVaries = false,
) => {
  if (max > min) {
    const formattedMin = min * 100 * quantity;
    const formattedMax = max * 100 * quantity;

    const MIN = formatMoney(formattedMin, moneyFormat);
    const MAX = formatMoney(formattedMax, moneyFormat);
    if (minPriceVaries) $product.find('.vp-prices .vp-prices-from').show();
    $product.find('.vp-prices .vp-prices-min').html(MIN);
    $product.find('.vp-prices .vp-prices-max').text(MAX);
    const discountEl = $product.find('.vp-prices .vp-prices-discount');
    const discountOriginalPrice = $product.find('.vp-original-prices .sales-rate').text();
    if (discountOriginalPrice !== '') {
      discountEl.text(discountOriginalPrice);
    } else {
      discountEl.text(calculateDiscount(discountEl, moneyFormat, max, min, min, max));
    }
    $product.find('.vp-prices').show();
    $product.find('.vp-original-prices').hide();
  } else {
    $product.find('.vp-original-prices').show();
    $product.find('.vp-prices').hide();
  }
};

export const populateLineItemTemplate = (
  $lineItem,
  line_compare_at_price,
  compare_at_price,
  final_line_price,
  final_price,
  moneyFormat,
  apiLineItems,
  variant_id,
) => {
  // Empty previously added discount tags
  const $currentlyDisplayedTags = $lineItem.find('.vp-prices-discount-tags');
  $currentlyDisplayedTags.html('');

  const lineItemDiscount = apiLineItems.filter((x) => x.line_level_discount_allocations);
  const discountFilter = lineItemDiscount.filter((x) => x.variant_id === variant_id);

  if (lineItemDiscount.length > 0) {
    if (discountFilter.length > 0)
      discountFilter[0].line_level_discount_allocations.forEach(
        ({ amount, discount_application }) => {
          $currentlyDisplayedTags.append(
            `<span class="vp-prices-discount-tag">${discount_application.title}</span>`,
          );
        },
      );
  }

  if (line_compare_at_price !== final_line_price || final_price !== compare_at_price) {
    $lineItem.find('.vp-prices .vp-prices-min').text(formatMoney(final_line_price, moneyFormat));
    $lineItem
      .find('.vp-prices .vp-prices-max')
      .text(formatMoney(line_compare_at_price, moneyFormat));
    $lineItem
      .find('.vp-prices .vp-prices-compare-at-price')
      .text(formatMoney(compare_at_price, moneyFormat));
    $lineItem.find('.vp-prices .vp-prices-final-price').text(formatMoney(final_price, moneyFormat));
    const discountEl = $lineItem.find('.vp-prices .vp-prices-discount');
    $lineItem
      .find('.vp-prices .vp-prices-discount')
      .text(
        calculateDiscount(
          discountEl,
          moneyFormat,
          line_compare_at_price,
          final_line_price,
          final_price,
          compare_at_price,
        ),
      );

    if (lineItemDiscount.length > 0) {
      updatePercentageCouponsDiscount($lineItem, apiLineItems, moneyFormat, variant_id);
    }

    $lineItem.find('.vp-prices').show();
    $lineItem.find('.vp-original-prices').hide();
  } else {
    if (lineItemDiscount.length < 1 || discountFilter.length === 0) {
      $lineItem.find('.vp-original-prices').show();
      $lineItem.find('.vp-prices').hide();
    }
    if (apiLineItems.length > 0) {
      updatePercentageCouponsDiscount($lineItem, apiLineItems, moneyFormat, variant_id);
    }
  }
};

export function calculateDiscount(discountEl, moneyFormat, n1, n2, n3, n4) {
  let discount;
  if (discountEl?.hasClass(ABSOLUTE_DISCOUNT_CLASS)) {
    discount = Math.floor(n1 - n2 + 0.1) * 100; // prevent 0.9999 from rounding to 0
    return (discount = `-${formatMoney(discount, moneyFormat, true)}`);
  } else {
    discount = Math.floor(100 - (n3 / n4) * 100 + 0.1); // prevent 0.9999 from rounding to 0
    return (discount = `-${discount}%`);
  }
}

function reducer(array) {
  return array.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr));
}

function updatePercentageCouponsDiscount($lineItem, apiLineItems, moneyFormat, variant_id) {
  const items = apiLineItems.filter((x) => x.variant_id === variant_id);
  const finalPrice = reducer(items.map((x) => x.final_line_price));
  const originPrice = reducer(items.map((x) => x.line_compare_at_price));
  const totalPercentage = (1 - finalPrice / originPrice) * 100;
  const discount = (finalPrice - originPrice) * 100;
  const vpPricesDiscount = $lineItem.find('.vp-prices .vp-prices-discount');
  $lineItem.find('.vp-prices .vp-prices-min').text(formatMoney(finalPrice * 100, moneyFormat));
  $lineItem.find('.vp-prices .vp-prices-max').text(formatMoney(originPrice * 100, moneyFormat));
  if (vpPricesDiscount?.hasClass(ABSOLUTE_DISCOUNT_CLASS)) {
    vpPricesDiscount.text(`${formatMoney(discount, moneyFormat, true)}`);
  } else {
    vpPricesDiscount.text(`-${totalPercentage.toFixed(0)}%`);
  }
}

export function populateAlreadyUsedCouponFeedback(couponInputValue) {
  const couponFeedback = document.querySelector(COUPONS_FEEDBACK_SELECTOR);
  couponFeedback.innerHTML = `<span class="warning"><b>${couponInputValue}</b> ${TRANSLATIONS.COUPON_ALREADY_USED}</span>`;
}

export function populateSuccessfullyAddedCouponFeedback(couponInputValue) {
  const couponFeedback = document.querySelector(COUPONS_FEEDBACK_SELECTOR);
  couponFeedback.innerHTML = `<div class="success"><b>${couponInputValue}</b> ${TRANSLATIONS.COUPON_SUCCESSFULLY_ADDED}</div>`;
}

export function populateInvalidCouponFeedback(couponInputValue) {
  const couponFeedback = document.querySelector(COUPONS_FEEDBACK_SELECTOR);
  couponFeedback.innerHTML = `<span class="warning"><b>${couponInputValue}</b> ${TRANSLATIONS.INVALID_COUPON}</span>`;
}

export function populateInvalidCouponForCartContentFeedback(couponInputValue) {
  const couponFeedback = document.querySelector(COUPONS_FEEDBACK_SELECTOR);
  const message = window.__ozjs.customerId
    ? `${TRANSLATIONS.INVALID_CART_FOR_COUPON} <b>${couponInputValue}</b>.`
    : TRANSLATIONS.CUSTOMER_FOR_COUPON;
  couponFeedback.innerHTML = `<span class="warning">${message}</span>`;
}

export function emptyCouponFeedback() {
  document.querySelector(COUPONS_FEEDBACK_SELECTOR).innerHTML = '';
}
