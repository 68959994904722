import { clDebug } from './utils/utils';

export function initLightRegister() {
  initLogin();
  initRegister();
}

function login(email, password) {
  var data = {
    'customer[email]': email,
    'customer[password]': password,
    form_type: 'customer_login',
    utf8: '✓',
  };
  var promise = $.ajax({
    url: '/account/login',
    type: 'POST',
    method: 'POST',
    data: data,
    dataType: 'html',
    async: true,
  });

  return promise;
}

function initLogin() {
  // Login form
  $('#customer_login')
    .off('submit')
    .on('submit', function (e) {
      e.preventDefault();

      $('#customer_login .errors').css('display', 'none');
      const loginEmail = $("[name='customer[email]']").val();
      const loginPassword = $("[name='customer[password]']").val();

      login(loginEmail, loginPassword)
        .done(function (html) {
          clDebug(`*** (html) :: initLogin(): ${html}`);

          // Errors below are from Shopify
          if (
            html.indexOf('class="errors"><ul><li>Incorrect email or password.') !== -1 ||
            html.indexOf('class="errors"><ul><li>E-mail ou mot de passe incorrect.') !== -1
          ) {
            // If login error
            $('#customer_login .errors, #customer_login .error').css('display', 'block');
            $('#AAI_email').val(loginEmail);
            $('#recover_email').val(loginEmail);
          } else {
            redirect();
          }
        })
        .fail(function (error) {
          console.log('error: ', error);
        });
    });
}

function initRegister() {
  // Create Customer form
  $('#create_customer')
    .off('submit')
    .on('submit', function (e) {
      e.preventDefault();
      var data = $(this).serialize();

      // Create new account
      $.post('/account', data)
        .done(function (data) {
          var logErrors = $(data).find('.errors').text();

          clDebug(`*** (data) :: initRegister(): ${data}`);

          if (logErrors != '' && logErrors != 'undefined') {
            $('#create_customer .errors').html(logErrors);
            $('#create_customer .errors').show();
          } else {
            redirect();
          }
        })
        .fail(function (error) {
          console.log('create customer form error', error);
        });
    });
}

function redirect() {
  const redirectUrl = window?.__ozjs?.privateSales?.urlRedirection;
  const localization = window?.__ozjs?.privateSales?.language;
  const checkoutUrl = getParameterByName('checkout_url');
  let prefix = '';
  if (!localization?.primary) {
    prefix += `/${localization?.locale}`;
  }

  // if we're on the lightregister page
  if (
    $(
      'body.page-lightregister, body.template-suffix-lightregister, .oz-lightregister, body.lightregister--template',
    ).length
  ) {
    window.location.href = redirectUrl;
    // if we're coming from the checkout, redirect to cart to create draft order
  } else if (checkoutUrl) {
    window.location.href = `${prefix}/cart`;
  } else {
    window.location.href = `${prefix}/account`;
  }
}

function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
