import { initOnScroll, pollFor, clDebug } from './utils/utils';
import { populatePriceTemplate } from './rendering';
import { getPricesAndPopulate } from './apiCalls';

// --- GENERIC NAMES FOR CLASSES AND ATTRIBUTES ---
const PRICE_CHANGED_CLASS = 'vp-price-changed';

// HANDLES
const HANDLE_DATA_ATTR = 'data-vp-handle';
const SELECTOR_WITH_HANDLE = `[${HANDLE_DATA_ATTR}]:not(.${PRICE_CHANGED_CLASS})`;
// ------------------------------------------------

/**
 * Initialise VP on HANDLEs
 */
export function runVPOnHandles(shopName, moneyFormat) {
  // STEP 1
  pollFor(SELECTOR_WITH_HANDLE, run);

  // STEP 2
  function run($e) {
    const $products = !$e || !$e.length ? $(SELECTOR_WITH_HANDLE) : $e;
    // clDebug(`*** (handles) :: step2 in run - $products: ${JSON.stringify($products)}`);

    // First prices transformation
    getProductPrices($products);

    // After scrolling, second prices transformation
    initOnScroll(() => {
      getProductPrices($(SELECTOR_WITH_HANDLE));
    });
  }

  // STEP 3
  function getProductPrices($products) {
    const productHandles = [];

    // Loop through the products on the collection page to get the product handles
    $products.each((_i, product) => {
      const $product = $(product);
      $product.addClass(PRICE_CHANGED_CLASS);
      const productHandle = $product.attr(HANDLE_DATA_ATTR);

      if (productHandle) {
        productHandles.push(productHandle);
      }
    });

    clDebug(
      `*** (handles) :: step3 in getProductPrices - productHandles: ${JSON.stringify(
        productHandles,
      )}`,
    );

    getPricesAndPopulate(populatePrices, shopName, productHandles, true);
  }

  // STEP 4
  function populatePrices(data) {
    clDebug(`*** (populatePrices) :: step4 in populatePrices - data: ${JSON.stringify(data)}`);
    const productPrices = data.products_prices;
    clDebug(
      `*** (populatePrices) :: step4-bis in populatePrices - productPrices: ${JSON.stringify(
        productPrices,
      )}`,
    );
    if (!productPrices) {
      return;
    }

    productPrices.forEach(({ min_compare_at_price, min_price, max_price, product_handle }) => {
      const $product = $(`[${HANDLE_DATA_ATTR}="${product_handle}"]`);
      if (min_compare_at_price > 0 && min_compare_at_price > min_price) {
        const minPriceVaries = min_price < max_price;
        clDebug(`*** (handles) :: step4 in forEach - $product: ${$product}`);

        populateProductGridItemPrice($product, min_compare_at_price, min_price, minPriceVaries);
      } else {
        const minPriceText = $product.find('.vp-original-prices .price').text();
        const minPrice = parseFloat(minPriceText);
        const maxPriceText = $product.find('.vp-original-prices .sales-price').text();
        const maxPrice = isNaN(parseFloat(maxPriceText)) ? minPrice : parseFloat(maxPriceText);

        if (maxPrice > minPrice) {
          $product.find('.vp-prices-max').text(deletePriceDoublons(maxPriceText));
          $product.find('.vp-prices-min').text(deletePriceDoublons(minPriceText));
          $product
            .find('.vp-prices-discount')
            .text(`${Math.round(100 - (minPrice / maxPrice) * 100)}%`);
        }
      }
    });
  }

  // STEP 5
  function populateProductGridItemPrice($product, max, min, minPriceVaries) {
    populatePriceTemplate($product, max, min, moneyFormat, 1, minPriceVaries);
  }

  function deletePriceDoublons(string) {
    return string
      .replace(',00', '')
      .split(' ')
      .filter((x) => !isNaN(parseFloat(x)))[0]
      .replace('/n', '');
  }
}
