/**
 * Disable Discount Ninja promotions
 */
function disableDiscountNinja() {
  document.dispatchEvent(new CustomEvent('limoniapps:discountninja:app:disable'));
}

/**
 * Refresh prices when USF is updated (new filters are selected, infinite scroll, etc.)
 */
function reloadPricesOnUSFUpdated() {
  window.usf?.event.add('sr_updated', function () {
    document.dispatchEvent(new Event('vp-rerender'));
  });
}

/**
 * Refresh prices when dynamic search is performed (USF, Algolia)
 */
function reloadPricesOnDynamicSearchPerformed() {
  $('input[type="search"], input[name="q"]')?.on('input', function () {
    document.dispatchEvent(new Event('vp-rerender'));
  });
}

export function handleThirdPartyApps() {
  disableDiscountNinja();
  reloadPricesOnUSFUpdated();
  reloadPricesOnDynamicSearchPerformed();
}
