/**
 * Format money, example of use:
 *
 *  const moneyFormat = '{{amount_with_comma_separator}}€';
 *  const min = formatMoney(formattedMin, moneyFormat); // result: 7,25€
 *
 */
export const formatMoney = (cents, format, currencyAfter = false) => {
  if (typeof cents === 'string') {
    if (!cents.includes('.')) {
      cents = cents.concat('00');
    } else {
      cents = cents.match(/\d*\.\d{0,2}/g)[0];
    }
    cents = cents.replace('.', '');
  }

  // Place currency symbol after if  currency symbol
  // const result = format.replace(/(.)(\{\{.*\}\})/, `$2$1`);

  var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/,
    formatString = currencyAfter
      ? format.replace(/(\S*)(\s|.?)(\{\{.*\}\})/, `$3$2$1`)
      : format || '${{amount}}';

  function defaultTo(value, defaultValue) {
    return value == null || value !== value ? defaultValue : value;
  }

  function formatWithDelimiters(number, precision, thousands, decimal) {
    precision = defaultTo(precision, 2);
    thousands = defaultTo(thousands, ',');
    decimal = defaultTo(decimal, '.');

    if (isNaN(number) || number == null) {
      return 0;
    }

    number = (number / 100.0).toFixed(precision);

    var parts = number.split('.'),
      dollarsAmount = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
      centsAmount = parts[1] ? decimal + parts[1] : '';

    return dollarsAmount + centsAmount;
  }

  var value = '';

  switch (formatString.match(placeholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_space_separator':
      value = formatWithDelimiters(cents, 2, ' ', '.');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, ',', '.');
      break;
    case 'amount_no_decimals_with_space_separator':
      value = formatWithDelimiters(cents, 0, ' ');
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',');
      break;
  }

  if (formatString.indexOf('with_comma_separator') !== -1) {
    return formatString.replace(placeholderRegex, value).replace(',00', '');
  } else {
    return formatString.replace(placeholderRegex, value).replace('.00', '');
  }
};
