/**
 * pollFor returns the $elem in the DOM we're looking for
 */
export function pollFor(target, callback) {
  let i = 1;
  let timeout;

  const poller = function (cb) {
    i++;
    let result;
    if (!$ || !$.length || !cb) return;

    if (typeof target === 'function') result = target();
    else result = $(target).length ? $(target) : false;

    if (result) {
      cb(result);
    } else {
      if (i > 30) {
        clearTimeout(timeout);
        return;
      }
      timeout = window.setTimeout(() => poller(callback), 50 * i);
    }
  };

  poller(callback);
}

export function clDebug(msg) {
  const TEST_MODE = window?.__ozjs?.privateSales?.testMode || false;
  if (TEST_MODE) {
    console.log(msg);
  }
}

/**
 * Prevent a function to run straight away, wait to scroll once to activate the callback
 */
export const initOnScroll = (cb) => {
  const $window = $(window);
  if (window.scrollY === 0) {
    $window.on('scroll', () => {
      cb();
      $window.off('scroll');
    });
  } else {
    cb();
  }
};
